import Vue from "vue"
import moment from "moment"

Vue.filter("convert_date", function (value) {
  if (!value) return ""
  return moment(value).format("DD MMMM YYYY", "id")
})

Vue.filter("price", (number = 0, decimals = 0, decPoint = ',', thousandsSep = '.') => {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  let n = !isFinite(+number) ? 0 : +number;
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  let dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
  let s = '';

  let toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k).toFixed(prec);
  }

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return `Rp ${s.join(dec)}`;
})

Vue.filter("pricing", (num) => {
  if (isNaN(num)) return "Not a Number"
  let price = ""
  const reverseNumber = num
    .toString()
    .split("")
    .reverse()
    .join("")
  const arrReverseNumber = [...Array(reverseNumber.length).keys()]
  arrReverseNumber.map(index => {
    if (index % 3 === 0) price += reverseNumber.substr(index, 3) + "."
  })

  return `Rp${
    price.split("", price.length - 1)
    .reverse()
    .join("")
  }`
})

Vue.filter("toRoman", (param) => {
  var arab = [1,4,5,9,10,40,50,90,100,400,500,900,1000];
  var roma = ["I","IV","V","IX","X","XL","L","XC","C","CD","D","CM","M"];
  var hasil='';
  
  for(var i=12; i >=0; i--) {
    while(param >= arab[i]) {
      param = param - arab[i];
      hasil = hasil + roma[i];
    }
  }
			
  return hasil
})

export default {
  moment
}